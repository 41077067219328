import React from 'react'
import Container from '../../components/shared/Container'
import Button from '../../components/shared/Button'
import { scrollToSection } from '../../utils/pageNavigation'
// @ts-ignore
import facebook from '../../assets/socials/facebook.png'
// @ts-ignore
import instagram from '../../assets/socials/instagram.png'
const Footer = () => {
  const renderSection = (section: React.ReactNode, isMain = false) => (
    <div
      className={`flex ${isMain ? 'flex-2' : 'flex-1'} flex-col justify-between h-[120px] mx-4`}
    >
      {section}
    </div>
  )
  const renderContactSection = () => {
    const sectionContent = (
      <>
        <div className="text-black text-md font-bold">
          <p className='mb-4'>Pozovite nas</p>
          <div>
            <a className="text-light-gray text-md font-light " href="tel:+381694733644">+381 69 4733 644</a>
          </div>
        </div>
      </>
    )
    return renderSection(sectionContent)
  }

  const renderPagesSection = () => {
    const sectionContent = (
      <>
        <div className="text-black text-md pb-2 font-bold mt-8 md:mt-0">
          Stranice
        </div>
        <div
          onClick={() => scrollToSection('examples')}
          className="text-light-gray text-md cursor-pointer"
        >
          Primeri
        </div>
        <div
          onClick={() => scrollToSection('tShirtSizes')}
          className="text-light-gray text-md cursor-pointer"
        >
          Veličine
        </div>
        <div
          onClick={() => scrollToSection('questions')}
          className="text-light-gray text-md cursor-pointer"
        >
          Pitanja
        </div>
      </>
    )
    return renderSection(sectionContent)
  }

  const renderSocialsSection = () => {
    const sectionContent = (
      <>
        <div>
          <div className="text-black text-md pb-4 font-bold mt-8 md:mt-0">
            Zaprati nas
          </div>
          <div className="flex flex-row">
            <div className="w-[30px] h-[30px] mr-4" >
              <a href="https://www.facebook.com/nosistamislis" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="Facebook Logo" />
              </a>
            </div>
            <div className="w-[30px] h-[30px]" >
              <a href="https://www.instagram.com/nosi.sta.mislis/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Instagram Logo" />
              </a>

            </div>
          </div>
        </div>
      </>
    )
    return renderSection(sectionContent)
  }

  const renderEmailSection = () => {
    const sectionContent = (
      <>
        <div className="text-black text-md pb-4 font-bold mt-8 md:mt-0">
          Pošalji nam email
        </div>
        <div className="text-light-gray text-md lg:mb-0">
          Pošalji nam email i mi ćemo te obaveštavati o novim artiklima na našem
          sajtu.
        </div>
        <div className="flex flex-row justify-between w-full mt-2 ">
          <input
            placeholder={'Email'}
            className="h-[35px] border p-2 rounded-md text-sm w-full mr-2"
          />
          <Button
            customStyles={'flex items-center justify-center h-[35px] w-[120px] pb-0 pt-0'}
            isMain={true}
            text={'Pošalji'}
            onClick={() => { }}
          />
        </div>
      </>
    )
    return renderSection(sectionContent, true)
  }

  return (
    <Container customStyles="bg-nsm-gray-300">
      <div className="flex flex-col md:flex-row border border-t-1 border-x-0 border-b-0 border-border-gray mt-24 lg:mt-32 pt-8 pb-12 w-full">
        {renderContactSection()}
        {renderPagesSection()}
        {renderSocialsSection()}
      </div>
    </Container>
  )
}

export default Footer
